import { useEffect, useContext } from "react";
import { navigate } from "gatsby";
import UserProfileContext from "../context/user-profile-context";
import GameContext from "../context/game-context";
import {GameOptions} from "../entities/game";

export default function useHasAddedPhoneNumberAndEmailRedirect(redirectUrl) {
  const { userProfile } = useContext(UserProfileContext);
  const { game, team } = useContext(GameContext);

  useEffect(() => {
    if (!game || !team || !userProfile) {
      return;
    }

    const phoneNumberCheck = !game.getOption(GameOptions.COLLECT_PHONES) ||
      team.users[userProfile.id]?.hasAddedPhoneNumber;
    const emailCheck = !game.getOption(GameOptions.COLLECT_EMAILS) ||
      team.users[userProfile.id]?.hasAddedEmail;
    const additionalFieldCheck = !game.requiresAdditionalField || userProfile.additionalFields[game.requiredAdditionalFieldName];

    if (phoneNumberCheck && emailCheck && additionalFieldCheck) {
        navigate(redirectUrl);
    }

  });
}
