import React, {useContext, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {LoadingSpinner, MarkdownPageLayout, PhoneInput} from "../../components/components";
import appContent from "../../markdown/app-content";
import UserProfileContext from "../../context/user-profile-context";
import GameContext from "../../context/game-context";
import {updateProfileOnTeam} from "../../services/firebase";
import useIsLoading from "../../hooks/useIsLoading";
import {AppAlertsContext} from "../../context/app-alerts-context";
import useHasAddedPhoneNumberAndEmailRedirect from "../../hooks/useHasAddedPhoneNumberAndEmailRedirect";
import {GameOptions} from "../../entities/game";
import ApplyCustomGameTheme from "../../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";

const PhoneRequiredPage = () => {
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [additionalFieldValue, setAdditionFieldValue] = useState("");
  const {userProfile, signUpOrUpdateLocalProfile} = useContext(UserProfileContext);
  const {game, team} = useContext(GameContext);
  const {isLoading, setIsLoading} = useIsLoading(false);
  const {popError} = useContext(AppAlertsContext);
  useHasAddedPhoneNumberAndEmailRedirect(`/game/in-game`);

  useEffect(() => {
    if (userProfile?.email) {
      setEmail(userProfile.email);
    }

    if (userProfile?.phoneNumber) {
      setPhone(userProfile.phoneNumber);
    }

    if (userProfile?.additionalFields[GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME]) {
      setAdditionFieldValue(
        userProfile?.additionalFields[GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME]
      );
    }
  }, [game, userProfile])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const additionalFields = game.requiredAdditionalFieldName ? (
      {[game.requiredAdditionalFieldName]: additionalFieldValue}
    ) : {};

    try {
      const newUserProfile = await signUpOrUpdateLocalProfile({
        name: userProfile.name,
        phoneNumber: phone,
        email: email,
        additionalFields,
      });
      await updateProfileOnTeam(game, team, newUserProfile, additionalFields);
    } catch (e) {
      popError(e.message);
    }
    setIsLoading(false);
  };

  return <MarkdownPageLayout
    logo="lg"
    markdown={appContent.inGame.phoneRequired.markdown}
  >
    <Form onSubmit={handleSubmit}>
      {game?.getOption(GameOptions.COLLECT_EMAILS) && (
        <Form.Group>
          <Form.Label>{appContent.inGame.phoneRequired.emailLabel}</Form.Label>
          <Form.Control
            value={email || ""}
            type="email"
            placeholder={appContent.inGame.phoneRequired.emailPlaceholder}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
          <div className="text-muted">{appContent.inGame.phoneRequired.emailHelpText}</div>
        </Form.Group>
      )}
      {game?.getOption(GameOptions.COLLECT_PHONES) && (
        <Form.Group>
          <Form.Label>{appContent.inGame.phoneRequired.phoneLabel}</Form.Label>
          <PhoneInput value={phone} onChange={(number) => setPhone(number)}/>
          <div className="text-muted">{appContent.inGame.phoneRequired.phoneHelperText}</div>
        </Form.Group>
      )}
      {game?.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME) && (
        <Form.Group>
          <Form.Label>{game.requiredAdditionalFieldName}</Form.Label>
          <Form.Control
            value={additionalFieldValue}
            type="text"
            onChange={(event) => setAdditionFieldValue(event.target.value)}
            required
          />
        </Form.Group>
      )}
      <Button className="mt-5" block type="submit">
        {appContent.inGame.phoneRequired.submitText}
      </Button>
    </Form>
    <LoadingSpinner isLoading={isLoading}/>
    <ApplyCustomGameTheme/>
  </MarkdownPageLayout>;
};

export default PhoneRequiredPage;
