import React, {useContext, useEffect} from "react";
import Proptypes from "prop-types";
import ReactHelmetContext from "../../../../context/react-helmet-context";
import {HEAD_CONTENT_ID} from "../../../../components/providers/react-helmet/ReactHelmetProvider";
import GameContext from "../../../../context/game-context";

const ApplyCustomGameTheme = ({usePrimaryGame, children}) => {
  const {game, gameOnHomePage} = useContext(GameContext);
  const {updateHeadContent} = useContext(ReactHelmetContext);

  const useGame = usePrimaryGame ? gameOnHomePage : game;

  useEffect(() => {
    const gameTheme = useGame?.customHeadContent;
    updateHeadContent(HEAD_CONTENT_ID.GAME_THEME_HEAD_CONTENT, gameTheme);
  }, [game, gameOnHomePage]);

  return children || null;
};

ApplyCustomGameTheme.propTypes = {
  usePrimaryGame: Proptypes.bool,
  children: Proptypes.node,
}


export default ApplyCustomGameTheme;
